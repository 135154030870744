import request from '@/utils/request'
// 抖音团购列表
export function getTableList(data) {
  return request('post', '/apm/douyin/shop/list', data)
}

// 同步列表
export function getRefreshList(data) {
  return request('post', '/apm/douyin/shop/refresh', data)
}

// 新增修改
export function getTableEdit(data) {
  return request('post', '/apm/douyin/shop/bind', data)
}

// 团购兑换订单
export function getOrderListByThird(data) {
  return request('post', '/apm/tuangou/page', data)
}

// 团购兑换订单退款
export function goRefundByThird(data) {
  return request('post', '/apm/tuangou/unified/order/buy/refund', data)
}

// 查询抖音门店
export function getDouyinShopList(data) {
  return request('post', '/apm/douyin/shop/listByMerchantId', data)
}
export function getMeiTuanShopList(data) {
  return request('post', '/apm/dianping/shop/listByMerchantId', data)
}
export function getMTShopList(data) {
  return request('post', '/apm/meituan/oauth/listByMerchantId', data)
}